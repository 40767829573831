@font-face{font-family:"Roboto";font-style:normal;font-weight:400;src:url(/static/media/roboto-regular.58bf8e0b.woff) format("woff")}@font-face{font-family:"Roboto";font-style:normal;font-weight:600;src:url(/static/media/roboto-medium.6422eac7.woff) format("woff")}*{box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;-ms-box-sizing:border-box}body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}#root{max-width:1440px;margin:0 auto}

.App{width:100%;margin:0 auto}

.header{max-width:1440px;width:100%;background-color:#181717;font-family:Roboto, Arial, Helvetica, sans-serif;position:fixed;top:0;z-index:1000}.header__nav{width:calc(1240 / 1440 * 100%);margin:0 auto;display:flex;justify-content:space-between;align-items:center;max-height:60px}.header__nav-list{padding:0;margin:0;display:flex;list-style-type:none}.header__logo{font-size:24px;line-height:20px;color:#fff}@media screen and (max-width: 425px){.header__logo{font-size:16px;line-height:12px;font-weight:bold}}.header__nav-link{text-decoration:none;color:#b6bcbf;margin-left:20px}.header__nav-link.active{color:#fff}

.project{width:86%;margin:0 auto;padding-top:100px;font-family:Roboto, Arial, Helvetica, sans-serif}.project__list{display:grid;grid-gap:20px;grid-template-columns:repeat(auto-fill, 300px);justify-content:center}@media screen and (max-width: 320px){.project__list{grid-template-columns:repeat(auto-fill, 100%)}}.project__title{font-style:normal;font-weight:normal;font-size:40px;line-height:46px;text-align:center}

.card{position:relative;width:100%;height:520px;border-radius:10px;background-color:#f5f6f7;transition:0.5s;color:#000;text-decoration:none;box-sizing:border-box;transition:0.5s}.card:hover{transform:translateY(-10px);box-shadow:0 5px 15px 0 rgba(0,0,0,0.5)}.card__title{margin-bottom:10px;font-style:normal;font-weight:normal;font-size:26px;line-height:30px}.card__description{font-style:normal;font-weight:normal;font-size:16px;line-height:22px}.card__image-block{width:100%;height:270px}.card__image{width:100%;height:100%;object-position:0 0;object-fit:cover;border-top-left-radius:10px;border-top-right-radius:10px;cursor:pointer;transition:5s}.card__image:hover{object-position:0 100%}.card__content{width:90%;margin:auto}.card__github-link{opacity:0}.card__github{display:flex;align-items:center;position:absolute;bottom:10px;transition:0.5s;cursor:pointer;text-decoration:none;color:#000;font-style:normal;font-weight:normal;font-size:12px;line-height:18px}.card__github-logo{margin-right:5px}.card__github-logo:hover{transform:scale(1.2);opacity:0.7}.card__github-logo:hover+.card__github-link{opacity:1;transition:0.5s}

.about{width:100%;font-family:Roboto, Arial, Helvetica, sans-serif;padding-top:100px}@media screen and (max-width: 425px){.about{padding-top:80px}}.about__container{width:calc(1240 / 1440 * 100%);margin:0 auto;display:flex;justify-content:space-between}@media screen and (max-width: 1165px){.about__container{flex-direction:column;align-items:center}}.about__text{max-width:600px}.about__image{background-image:url(/static/media/IMG_avatar.ab2e2034.jpg);width:400px;height:400px;background-repeat:no-repeat;background-size:cover;border-radius:50%}@media screen and (max-width: 425px){.about__image{width:275px;height:275px}}.about__title{font-style:normal;font-weight:normal;font-size:40px;line-height:46px}@media screen and (max-width: 425px){.about__title{text-align:center}}.about__description{font-style:normal;font-weight:normal;font-size:18px;line-height:24px}

.footer{height:80px;width:calc(1240 / 1440 * 100%);margin:40px auto 0;display:flex;justify-content:space-between;align-items:center;font-family:Roboto, Arial, Helvetica, sans-serif}.footer__author{font-style:normal;font-weight:normal;font-size:16px;line-height:22px;color:#b6bcbf}.footer__link-container{display:flex}.footer__link{cursor:pointer;width:22px;height:22px;margin-right:10px}.footer__link:hover{opacity:0.8;transform:scale(1.2)}.footer__tel{width:22px;height:22px}.footer__mail{width:22px;height:22px}

